import "design-system/styles/index.scss";

import { PropsWithChildren, useEffect } from "react";
import dynamic from "next/dynamic";
import Head from "next/head";
import { LazyComponentsProvider } from "design-system/components/system/lazy-component/use-lazy-components";
import { TranslationsProvider } from "design-system/hooks/use-translations";
import { frontendComponents } from "frontend/components";
import { GetServerSideProps } from "next";
import { RenderContentfulEntry } from "frontend/contentful/components/utils/render-contentful-entry";
import {
  ContentfulLivePreview,
  useContentful,
} from "frontend/hooks/use-contentful";
import { createServerContext } from "frontend/lib/create-server-context";
import { PageProps } from "frontend/types";
import { PagePropsProvider, usePageProps } from "frontend/hooks/use-page-props";
import { getPageProps } from "frontend/contentful/lib/get-page-props";
import { getContentfulRedirect } from "frontend/contentful/lib/get-redirect";
import { StoreProvider } from "design-system/store/store-provider";
import { logEvent } from "frontend/lib/log-event";
import { userAccess } from "frontend/contentful/components/utils/get-user-access";
import { getLocaleRedirect } from "frontend/contentful/components/utils/get-locale-redirect";

const Error404Page = dynamic(() =>
  import("frontend/contentful/components/pages/error-404.contentful").then(
    (m) => ({ default: m.Error404Page }),
  ),
);

function RenderPage() {
  const { entry, status } = usePageProps();
  const { useContentfulLiveUpdates } = useContentful();

  const liveEntry = useContentfulLiveUpdates(entry);

  if (!liveEntry || status === 404) {
    return <Error404Page />;
  }

  return <RenderContentfulEntry entry={liveEntry} />;
}

function WrappedTranslationsProvider({
  locale,
  children,
}: PropsWithChildren<{
  locale: string;
}>) {
  return (
    <TranslationsProvider locale={locale}>{children}</TranslationsProvider>
  );
}

export default function Page(props: PageProps) {
  useEffect(() => {
    // set props on window for debugging
    window.props = props;
  }, [props]);

  return (
    <StoreProvider location={props.request} framework={{ Head }}>
      <LazyComponentsProvider components={frontendComponents}>
        <PagePropsProvider {...props}>
          <WrappedTranslationsProvider locale={props?.locale}>
            <ContentfulLivePreview isPreview={props?.contentful?.isPreview}>
              <RenderPage />
            </ContentfulLivePreview>
          </WrappedTranslationsProvider>
        </PagePropsProvider>
      </LazyComponentsProvider>
    </StoreProvider>
  );
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({
  req,
  res,
  resolvedUrl,
  locale,
  defaultLocale,
  locales,
}) => {
  const ctx = createServerContext({
    slug: resolvedUrl,
    headers: req.headers,
    locale: locale,
    defaultLocale: defaultLocale,
    supportedLocales: locales,
  });

  const url = `${ctx.request.domain}.hbs.edu${ctx.request.path}${ctx.request.search}`;
  const timing = Date.now();

  const { pageProps } = await getPageProps({ ctx });

  if (!pageProps.entry || !pageProps.site) {
    const redirect = await getContentfulRedirect(ctx);

    if (redirect) {
      console.info(`Redirect from ${url} to ${redirect.destination}`);
      return { redirect };
    }

    pageProps.status = 404;
  }

  if (pageProps.status === 404) {
    res.statusCode = 404;
  }

  // Set status code to 500 if there are any errors so that the CDN can use a backup version of the page
  if (ctx.errors.length > 0) {
    res.statusCode = 500;
  }

  // allow setting a failure rate for testing
  if (ctx.request.headers["x-failure-rate"]) {
    const rate = parseFloat(ctx.request.headers["x-failure-rate"] as string);
    if (Math.random() < rate / 100) {
      res.statusCode = 500;
    }
  }

  // Check if user is logged in and has the correct permissions by using a seperate function
  const redirect = await userAccess(ctx, resolvedUrl, pageProps);

  if (redirect?.destination) {
    return { redirect };
  } else if (redirect?.props) {
    pageProps.session = redirect.props.session;
  }

  // Check if current request is for a locale that doesn't have translations
  const localeRedirect = getLocaleRedirect(ctx, resolvedUrl, pageProps);
  if (localeRedirect?.destination) {
    return { redirect: localeRedirect };
  }

  logEvent({
    event: "pageview",
    ms: Date.now() - timing,
    status: res.statusCode,
    url: req.url || "",
  });

  if (
    !ctx.contentful.isPreview &&
    !pageProps.session?.user.loggedIn &&
    res.statusCode !== 500
  ) {
    // cache anonymous and non-preview content for 5 min
    res.setHeader("cache-control", "public, s-maxage=300, stale-if-error=7200");
  }

  // for security, remove cookies from the request object
  // this is to prevent any sensitive HTTP only cookies from being exposed
  pageProps.request.cookies = {};
  return { props: pageProps };
};
